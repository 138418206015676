
  export default {
    name: 'CookiePopup',
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    computed: {
      modalBg() {
        return this.$colorMode.preference
      },
      modalText() {
        return this.$colorMode.preference === 'dark' ? 'light' : 'dark'
      },
    },
    methods: {
      closePopupModal() {
        this.$cookies.set(this.value.action, true, {
          maxAge: 60 * 15,
        })
        this.$bvModal.hide(this.value.id)
      },
    },
  }
  