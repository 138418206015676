
export default {
  name: 'BielbitDropDownItem',
  props: {
    positionHeading: {
      type: String,
      required: true,
    },
    positionDescription: {
      type: String,
      required: true,
    },
    positionLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeDropdown(event) {
      const id =
        event.target.parentNode.parentNode.parentNode.parentNode.parentNode.id.replace(
          'BV_toggle_menu_',
          'BV_toggle_'
        )
      // console.log(id)
      document.getElementById(id).click()
      document.querySelector('#hamburgerMenu').click()
    },
  },
}
